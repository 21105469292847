import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#25a0e2",
        secondary: "#f6f8fa",

        greyVariant1: "#808080",
        greyVariant2: "#8c8f9d",
        greyVariant3: "#878a99",
        greyVariant4: "#495057",

        blueVariant1: "#def1fb",

        greenVariant1: "#d9f5f0",
        greenVariant2: "#00bd9d",
        greenVariant3: "#00bd9d",

        BlueColorVariant1: "#0a6d9c",
        BlueColorVariant2: "#0a6d91",
        BlueColorVariant3: "#2cb2f1",

        redVariant1: "#FECFD8",

        DarkBlue: "#050a30",
        NavyBlue: "#000c66",
        Blue2: "#0000ff",
        MidNightBlue: "#145da0",
        greenText: "#116530",
        unReadNotificationColor: "#B3E5FC",
        readNotificationColor: "#E1F5FE",
      },
    },
  },
});
