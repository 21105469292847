<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <div id="particles-js" class="HomePage HomePageBG">
      <div class="HomePageOverlay"></div>
      <div class="HomePageShape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>
    <div class="HomePageContent">
      <v-row no-gutters dense>
        <v-col cols="12" align="center">
          <v-img class="imageClass" contain max-width="200px" src="@/assets/vozcare_logo_png.png" />
        </v-col>
        <v-col cols="12" align="center">
          <v-card class="HomePageContentCard elevation-0 px-3 py-8" outlined>
            <div class="text-center textVariant1 pt-4">Welcome back <span class="wave">👋</span></div>
            <v-card-text>
              <v-window v-model="loginStepper" class="pl-0">
                <v-window-item :value="1">
                  <v-card-text>
                    <v-form ref="form" @submit.prevent lazy-validation>
                      <v-text-field
                        flat
                        dense
                        outlined
                        label="Email ID *"
                        class="textVariant3"
                        @keydown.space="preventLeadingSpace"
                        @keydown.enter="ValidateSignUpForm()"
                        prepend-inner-icon="mdi-account"
                        :rules="[(v) => !!v || 'Email ID is required']"
                        v-model="Login.EmailID"></v-text-field>
                      <v-text-field
                        flat
                        dense
                        outlined
                        label="Password *"
                        class="textVariant3"
                        :type="isPwdLogin ? 'text' : 'password'"
                        @click:append="isPwdLogin = !isPwdLogin"
                        @keydown.space="preventLeadingSpace"
                        @keydown.enter="ValidateSignUpForm()"
                        required
                        :append-icon="isPwdLogin ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock"
                        :rules="[(v) => !!v || 'Password is required']"
                        v-model="Login.Password"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0">
                    <v-checkbox
                      dense
                      class="my-0 py-0 ml-1"
                      v-model="rememberme"
                      label="Remember Me"
                      :disabled="Login.EmailID === '' && Login.Password === ''" />
                    <v-spacer></v-spacer>
                    <v-btn x-small text class="fontSize mb-4" color="primary text-capitalize fontStyle" @click="loginStepper = 3"
                      >Forgot Password ?</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions class="pt-0">
                    <v-btn
                      block
                      :loading="loading"
                      tile
                      depressed
                      class="borderRadius fontStyle"
                      color="greenVariant3"
                      dark
                      @click="ValidateSignUpForm()"
                      >Sign In</v-btn
                    >
                  </v-card-actions>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text>
                    <v-form ref="formCP" lazy-validation>
                      <v-text-field
                        flat
                        dense
                        outlined
                        class="textVariant3"
                        label="Enter New Password *"
                        :type="isPwdNew ? 'text' : 'password'"
                        @click:append="isPwdNew = !isPwdNew"
                        required
                        :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock"
                        :rules="[(v) => !!v || 'Password is required']"
                        v-model="Password.NewPassword"></v-text-field>
                      <v-text-field
                        flat
                        dense
                        outlined
                        class="textVariant3"
                        label="Confirm New Password *"
                        :type="isPwdConfirm ? 'text' : 'password'"
                        @click:append="isPwdConfirm = !isPwdConfirm"
                        required
                        :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock"
                        :rules="[(v) => !!v || 'Password is required']"
                        v-model="Password.ConfirmPassword"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="pt-0 pb-8 px-4">
                    <v-btn
                      block
                      :loading="loading"
                      tile
                      class="borderRadius fontStyle"
                      color="greenVariant3"
                      dark
                      @click="ValidateConfirmPasswordForm()"
                      >Sign In</v-btn
                    >
                  </v-card-actions>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text>
                    <v-form ref="formOTP">
                      <div class="text-center">Please Enter The Registered Email</div>
                      <v-text-field
                        flat
                        dense
                        outlined
                        label="Email"
                        v-model="OTPEmail"
                        :rules="[(v) => !!v || 'Email ID is required']"
                        class="mt-4 borderRadius fontSize">
                      </v-text-field>
                      <div class="fontSize text-center">*Please enter the e-mail used for Vozcare login/registration</div>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="primary fontStyle" small @click="loginStepper = 1">Back To Sign In</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary fontStyle" small @click="ValidateOTP()">Next</v-btn>
                  </v-card-actions>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text>
                    <v-form ref="formConfirmOTP">
                      <div class="text-center">Enter the Vozcare Activation Code</div>
                      <v-text-field
                        flat
                        dense
                        outlined
                        label="Code *"
                        v-model="ConfirmOTP"
                        :rules="[(v) => !!v || 'Code is required']"
                        class="mt-4 borderRadius">
                      </v-text-field>
                      <v-text-field
                        flat
                        dense
                        outlined
                        label="Enter New Password *"
                        v-model="ConfirmNewPassword"
                        :rules="[(v) => !!v || 'Password is required']"
                        class="borderRadius fontSize mt-4"
                        :type="isPwdConfirmCode ? 'text' : 'password'"
                        @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                        required
                        :append-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock">
                      </v-text-field>
                      <div class="fontSize text-center">Enter the Vozcare Activation Code sent to your registered Email.</div>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="greenVariant3" dark class="fontStyle borderRadius" small @click="loginStepper = 1">Back To Sign In</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="greenVariant3" dark class="fontStyle borderRadius" small @click="ValidateEnteredOTP()">Next</v-btn>
                  </v-card-actions>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text>
                    <v-form ref="formEP" lazy-validation>
                      <v-text-field
                        flat
                        dense
                        outlined
                        class="textVariant3"
                        label="Enter Old Password *"
                        :type="isPwdNew ? 'text' : 'password'"
                        @click:append="isPwdNew = !isPwdNew"
                        required
                        :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock"
                        :rules="[(v) => !!v || 'Password is required']"
                        v-model="ResetExpiredPasswordObject.OldPassword"></v-text-field>
                      <v-text-field
                        flat
                        dense
                        outlined
                        class="textVariant3"
                        label="Enter New Password *"
                        :type="isPwdConfirm ? 'text' : 'password'"
                        @click:append="isPwdConfirm = !isPwdConfirm"
                        required
                        :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock"
                        :rules="[(v) => !!v || 'Password is required']"
                        v-model="ResetExpiredPasswordObject.NewPassword"></v-text-field>
                      <v-text-field
                        flat
                        dense
                        outlined
                        class="textVariant3"
                        label="Confirm New Password *"
                        :type="isPwdConfirm ? 'text' : 'password'"
                        @click:append="isPwdConfirm = !isPwdConfirm"
                        required
                        :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock"
                        :rules="[(v) => !!v || 'Password is required']"
                        v-model="ResetExpiredPasswordObject.ConfirmNewPassword"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="pt-0 pb-8 px-4">
                    <v-btn
                      block
                      :loading="loading"
                      tile
                      class="borderRadius fontStyle"
                      color="greenVariant3"
                      dark
                      @click="ValidateResetExpirePasswordForm()"
                      >Sign In</v-btn
                    >
                  </v-card-actions>
                </v-window-item>
                <v-window-item :value="6">
                  <v-row no-gutters>
                    <v-col cols="12" align="center">
                      <v-img max-width="200px" src="@/assets/logout.gif" />
                    </v-col>
                    <v-col cols="12" align="center" class="pt-4">
                      <div class="textVariant4">You are Logged out</div>
                    </v-col>
                    <v-col cols="12" align="center" class="pt-1">
                      <div class="textVariant5">Thanks for using <span class="font-weight-bold">Vozcare</span></div>
                    </v-col>
                    <v-col cols="12" align="center" class="pt-4">
                      <v-btn block depressed color="#00bd9d" dark class="text-capitalize">Sign In</v-btn>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { BackgroundParticles } from "@/mixins/particle.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { Auth } from "aws-amplify";

import { SignInAPI } from "@/mixins/RestAPI/SignInAPI.js";
import { ConfirmPasswordAPI } from "@/mixins/RestAPI/ConfirmPasswordAPI.js";
import { SendOTPConfirmPassword } from "@/mixins/RestAPI/SendOTPConfirmPassword.js";
import { ConfirmOTP } from "@/mixins/RestAPI/ConfirmOTP.js";
import { ResetExpiredPassword } from "@/mixins/RestAPI/ResetExpiredPassword.js";
export default {
  components: {
    Snackbar,
  },
  mixins: [SignInAPI, ConfirmPasswordAPI, ResetExpiredPassword, SendOTPConfirmPassword, ConfirmOTP, BackgroundParticles],
  data: () => ({
    isPwdNew: false,
    isPwdLogin: false,
    isPwdConfirm: false,
    isPwdConfirmCode: false,
    loginStepper: 1,
    rememberme: false,
    loading: false,

    session: {},
    Login: {
      EmailID: "",
      Password: "",
    },
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    ResetExpiredPasswordObject: {
      OldPassword: "",
      NewPassword: "",
      ConfirmNewPassword: "",
    },
    sign_up: {
      user_name: "",
      user_email_id: "",
    },
    OTPEmail: "",
    ConfirmOTP: "",
    ConfirmNewPassword: "",
    SnackBarComponent: {},
    countryCodeItems: [
      { country_name: "India", country_code: "+91" },
      { country_name: "USA", country_code: "+1" },
    ],
  }),
  watch: {
    model(val) {
      if (this.cardContent.length == val) {
        this.clearItervalMethod();
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.loginStepper) {
        case 1:
          return "Customer - Sign In";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        case 5:
          return "Reset Expired Password";
        default:
          return "Account created";
      }
    },
  },
  mounted() {
    this.initParticles();
    this.CheckRememberMe();
  },
  methods: {
    async ValidateResetExpirePasswordForm() {
      if (this.$refs.formEP.validate()) {
        if (this.ResetExpiredPasswordObject.ConfirmNewPassword == this.ResetExpiredPasswordObject.NewPassword) {
          this.loading = true;
          let resetExpiredPasswordResult = await this.ResetExpiredPasswordMethod(
            this.Login.EmailID,
            this.ResetExpiredPasswordObject.OldPassword,
            this.ResetExpiredPasswordObject.NewPassword
          );
          this.loading = false;
          if (resetExpiredPasswordResult.data.status == "success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: "Password Changed Successfully!!",
            };
            this.loginStepper = 1;
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Mismatch!!",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields Marked With Asterisks (*) Are Mandatory",
        };
      }
    },
    validateSignUpMethod() {
      if (this.$refs.formSignUp.validate()) {
        this.signUpMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Input",
        };
      }
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ") e.target.value = e.target.value.replace(/^\s*/, "");
    },
    CheckRememberMe() {
      if (localStorage.getItem("currentuseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
        this.rememberme = true;
      }
    },
    async ConfirmOTPMethod() {
      let ConfirmOTPMethodResult = await this.ConfirmOTPMainMethod(this.OTPEmail.trim(), this.ConfirmOTP.trim(), this.ConfirmNewPassword.trim());
      if (ConfirmOTPMethodResult.data.status == "success") {
        this.$refs.formConfirmOTP.reset();
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Password Successfully Changed ",
        };
        this.loginStepper = 1;
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Something Went Wrong!!",
        };
      }
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        this.ConfirmOTPMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    async SendOTPEmail() {
      let SendOTPConfirmPasswordMethodResult = await this.SendOTPConfirmPasswordMethod(this.OTPEmail.trim());
      if (SendOTPConfirmPasswordMethodResult.data.status == "success") {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "OTP Sent To Registered Email",
        };
        this.loginStepper = 4;
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Something Went Wrong!!",
        };
      }
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        let confirmPasswordResult = await this.ConfirmPasswordAPIMethod(
          this.Login.EmailID.trim(),
          this.Password.ConfirmPassword.trim(),
          this.session
        );
        if (confirmPasswordResult.data.status == "success") {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        } else {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: confirmPasswordResult.data.errorMessage,
          };
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async SignIn() {
      try {
        this.loading = true;
        let signInResult = await this.SignInAPIMethod(this.Login.EmailID.trim(), this.Login.Password);
        if (signInResult.data.status == "success") {
          if (signInResult.data.status_code == 1) {
            this.mainSignInMethod();
          } else if (signInResult.data.status_code == 2) {
            this.loading = false;
            this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
            this.loginStepper = 2;
            this.session = signInResult.data.session;
          } else if (signInResult.data.status_code == 3) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: signInResult.data.message,
            };
            this.loading = false;
            this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
            this.loginStepper = 5;
            this.session = signInResult.data.session;
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: signInResult.data.errorMessage,
          };
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async mainSignInMethod() {
      try {
        const user = await Auth.signIn(this.Login.EmailID.trim(), this.Login.Password.trim());
        this.user = user;
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.loginStepper = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.ActivateMethod();
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    ActivateMethod() {
      setTimeout(() => this.$router.push("/LandingPage"), 500);
    },
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
  },
};
</script>

<style>
.HomePageBG {
  background-image: url("@/assets/bgImg.jpg") !important;
  background-position: center !important;
  background-size: cover !important;
}
.HomePageBG .HomePageOverlay {
  background-image: linear-gradient(to right, #1b8fcd, #25a0e2cf) !important;
  opacity: 0.9 !important;
}
.HomePage {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 360px !important;
}
.HomePageOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
}
.HomePageShape {
  position: absolute !important;
  bottom: -10px !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
  pointer-events: none !important;
}
.HomePageShape > svg {
  width: 100% !important;
  height: auto !important;
  fill: #fff !important;
}
.HomePageContent {
  position: relative !important;
  z-index: 2 !important;
  justify-content: center !important;
  top: 20% !important;
}
.HomePageContent .HomePageContentCard {
  max-height: 400px !important;
  max-width: 450px !important;
}
.textVariant1 {
  font-family: sans-serif !important;
  color: #25a0e2 !important;
  font-size: 1.09375rem !important;
  font-weight: 600 !important;
}
.textVariant2 {
  font-family: sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.88rem !important;
}
.textVariant3 {
  font-size: 0.75rem !important;
}
.textVariant4 {
  font-family: sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.09375rem !important;
  color: #495057 !important;
}
.textVariant5 {
  font-family: sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.09375rem !important;
  color: #c2c3cb !important;
}
</style>
