<template>
  <v-overlay :value="overlay">
    <v-img :max-width="maxWidth" src="@/assets/favicon.png"></v-img>
    <v-progress-linear indeterminate color="primary" width="20"> </v-progress-linear>
    <div class="white--text text-center">Please wait...</div>
  </v-overlay>
</template>
<script>
export default {
  props: {
    overlay: Boolean,
  },
  data: () => ({
    count: 150,
    setInterval: -1,

    maxWidth: "150px",
  }),
  watch: {
    overlay(value) {
      if (value) {
        clearInterval(this.setInterval);
        this.count = 120;
        this.setInterval = setInterval(() => {
          this.count += 10;
        }, 500);
      } else {
        this.count = 120;
        clearInterval(this.setInterval);
      }
    },
    count(value) {
      if (value == 160) {
        this.count = 120;
      }
      if (value) {
        this.maxWidth = `${this.count}px`;
      }
    },
  },
};
</script>
