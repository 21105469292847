<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogLogoutWarning" persistent max-width="400px">
      <v-card height="200px">
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div>Logging out</div>
        </v-toolbar>
        <v-card-text class="text-center pb-0 mt-4">
          <v-icon size="50px" color="red">mdi-exit-to-app</v-icon>
          <div>
            You are not allowed to access the portal anymore as you got deactivated. Logging Out In <b>{{ count }}</b> Second(s)
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogLogoutWarning: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    count: 3,
    Toggle: 0,
    logoutInterval: -1,

    loading: false,
    SnackBarComponent: {},
  }),
  mounted() {
    this.count = 5;
    this.logoutInterval = setInterval(() => {
      this.count -= 1;
      if (this.count == 0) {
        this.$router.push("/");
        clearInterval(this.logoutInterval);
      }
    }, 1000);
  },
  methods: {
    dialogLogoutWarningEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
