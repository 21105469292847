import { GetCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export const GetCurrentUser = {
  data() {
    return {
      getCurrentUserDetailsObject: {},
      overlay: false,
      user_type: "",
    };
  },
  methods: {
    async GetCurrentUser(route) {
      try {
        this.overlay = true;

        let result = await API.graphql(
          graphqlOperation(GetCurrentUserDetails, {
            input: {
              user_email_id: this.$store.getters.get_user_email.trim(),
            },
          })
        );
        this.getCurrentUserDetailsObject = JSON.parse(result.data.GetCurrentUserDetails).data.items[0];

        this.$store.commit("SET_CURRENTUSER_DETAILS", JSON.parse(result.data.GetCurrentUserDetails).data.items[0]);
        if (route) {
          this.hasaccessRoutesList = [];
          let modulesHasAccessList = JSON.parse(result.data.GetCurrentUserDetails).data.items[0].role_details.role_modules;
          for (let i = 0; i < modulesHasAccessList.length; i++) {
            let module_rights = modulesHasAccessList[i].module_rights;
            let module_description = modulesHasAccessList[i].module_description.split("-")[0];
            let routeObject = {
              ...this.RouterList.filter((route) => route.MenuName == module_description)[0],
            };
            for (let key in routeObject) {
              modulesHasAccessList[i][key] = routeObject[key];
            }
            if (module_rights.view || module_rights.create || module_rights.edit || module_rights.delete)
              modulesHasAccessList[i].hasVisibility = true;
            else modulesHasAccessList[i].hasVisibility = false;
          }
          let hasaccessRoutesList = modulesHasAccessList.filter((route) => route.hasVisibility == true && route.module_index);
          this.hasaccessRoutesList = hasaccessRoutesList.sort((a, b) => Number(a.module_index) - Number(b.module_index));

          // const data = this.RouterList.filter((route) => route.MenuName == "Roles")[0];
          // data.active = false;
          // data.hasVisibility = true;
          // data.has_child_routes = false;
          // data.module_description = "Roles";
          // data.module_name = "Roles";
          // data.module_rights = {
          //   view: true,
          //   create: true,
          //   edit: true,
          //   delete: true,
          // };
          // this.hasaccessRoutesList.push(data);

          if (this.hasaccessRoutesList.length > 0) {
            this.hasaccessRoutesList[0].active = true;
            if (this.$route.name == "LandingPage") {
              this.$router.push(this.hasaccessRoutesList[0].MenuRoute);
            } else {
              let findRoute = this.hasaccessRoutesList.find((routeObj) => routeObj.MenuRoute == this.$route.name);
              if (findRoute) {
                this.checkRouteMethod(findRoute);
              } else {
                this.$router.push(this.hasaccessRoutesList[0].MenuRoute);
              }
            }
          } else {
            this.$router.push("/NoAccess");
          }

          this.$store.commit("SET_IS_REPORT_ISSUE_ROUTE", false);
        }
        this.overlay = false;
        return "SUCCESS";
      } catch (error) {
        this.overlay = false;
        this.$router.push("/");
        return "ERROR";
      }
    },
  },
};
