import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    image_url: "",
    getPreviousRoute: "",

    isReportIssueRoute: false,

    getIssueDetails: {},
    currentFormObject: {},
    currentRouteObject: {},

    GetCurrentUser: [],
    GetCurrentOrg: [],
    GetTeamList: [],
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_current_org_details: (state) => {
      return state.GetCurrentOrg;
    },
    get_cuurent_product_form_object: (state) => {
      return state.currentFormObject;
    },
    get_current_route_object: (state) => {
      return state.currentRouteObject;
    },
    get_issue_details: (state) => {
      return state.getIssueDetails;
    },
    get_previous_route: (state) => {
      return state.getPreviousRoute;
    },
    get_is_report_issue_route: (state) => {
      return state.isReportIssueRoute;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    GET_CURRENTORG_DETAILS(state, GetCurrentOrgDetails) {
      state.GetCurrentOrg = GetCurrentOrgDetails;
    },
    SET_CURRENT_PRODUCT_FORM_OBJECT(state, currentFormObject) {
      state.currentFormObject = currentFormObject;
    },
    SET_CURRENT_ROUTE_OBJECT(state, currentRouteObject) {
      state.currentRouteObject = currentRouteObject;
    },
    SET_ISSUE_DETAILS(state, getIssueDetails) {
      state.getIssueDetails = getIssueDetails;
    },
    SET_PREVIOUS_ROUTE(state, route) {
      state.getPreviousRoute = route;
    },
    SET_IS_REPORT_ISSUE_ROUTE(state, isReportIssueRoute) {
      state.isReportIssueRoute = isReportIssueRoute;
    },
  },
  actions: {},
  modules: {},
});
