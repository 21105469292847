import axios from "axios";
export const SendOTPConfirmPassword = {
  data: () => ({}),
  methods: {
    async SendOTPConfirmPasswordMethod(user_email_id) {
      try {
        var data = JSON.stringify({
          command: "userForgotPassword",
          user_email_id,
        });
        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://cmjo10iqb9.execute-api.ap-south-1.amazonaws.com/dev/user_auth/signin",
          headers: {},
          data: data,
        };

        let result = await axios(config);
        return result;
      } catch (error) {
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
  },
};
