import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import NoAccess from "../views/MainHomePages/NoAccess.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/MetricsOverview",
        name: "MetricsOverview",
        // route level code-splitting
        // this generates a separate chunk (MetricsOverview.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "MetricsOverview" */ "../views/ChildHomePages/MetricsOverview.vue"),
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        // route level code-splitting
        // this generates a separate chunk (Dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Dashboard" */ "../views/ChildHomePages/Dashboard.vue"),
      },
      {
        path: "/Campaigns",
        name: "Campaigns",
        // route level code-splitting
        // this generates a separate chunk (Campaigns.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Campaigns" */ "../views/ChildHomePages/Campaigns.vue"),
      },
      {
        path: "/Lists",
        name: "Lists",
        // route level code-splitting
        // this generates a separate chunk (Lists.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Lists" */ "../views/ChildHomePages/Lists.vue"),
      },
      {
        path: "/Clients",
        name: "Clients",
        // route level code-splitting
        // this generates a separate chunk (Clients.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Clients" */ "../views/ChildHomePages/Clients.vue"),
      },
      {
        path: "/Products",
        name: "Products",
        // route level code-splitting
        // this generates a separate chunk (Products.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Products" */ "../views/ChildHomePages/Products.vue"),
      },
      {
        path: "/Roles",
        name: "Roles",
        // route level code-splitting
        // this generates a separate chunk (Roles.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Roles" */ "../views/ChildHomePages/Roles.vue"),
      },
      {
        path: "/Users",
        name: "Users",
        // route level code-splitting
        // this generates a separate chunk (Users.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Users" */ "../views/ChildHomePages/Users.vue"),
      },
      {
        path: "/Locations",
        name: "Locations",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Locations" */ "../views/ChildHomePages/Locations.vue"),
      },
      {
        path: "/Teams",
        name: "Teams",
        // route level code-splitting
        // this generates a separate chunk (AgentGroup.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "AgentGroup" */ "../views/ChildHomePages/Teams.vue"),
      },
      {
        path: "/FieldRepository",
        name: "FieldRepository",
        // route level code-splitting
        // this generates a separate chunk (FieldRepository.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "FieldRepository" */ "../views/ChildHomePages/FieldRepository.vue"),
      },
      {
        path: "/OwnForm",
        name: "OwnForm",
        // route level code-splitting
        // this generates a separate chunk (OwnForm.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "OwnForm" */ "../views/ChildHomePages/OwnForm.vue"),
      },
      {
        path: "/OwnData",
        name: "OwnData",
        // route level code-splitting
        // this generates a separate chunk (OwnData.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "OwnData" */ "../views/ChildHomePages/OwnData.vue"),
      },
      {
        path: "/PBTB",
        name: "PBTB",
        // route level code-splitting
        // this generates a separate chunk (PBTB.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "PBTB" */ "../views/ChildHomePages/PBTB.vue"),
      },
      {
        path: "/DataModificationRequests",
        name: "DataModificationRequests",
        // route level code-splitting
        // this generates a separate chunk (DataModificationRequests.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "DataModificationRequests" */ "../views/ChildHomePages/DataModificationRequests.vue"),
      },
      {
        path: "/DNDList",
        name: "DNDList",
        // route level code-splitting
        // this generates a separate chunk (DNDList.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "DNDList" */ "../views/ChildHomePages/DNDList.vue"),
      },
      {
        path: "/DeDuplicates",
        name: "DeDuplicates",
        // route level code-splitting
        // this generates a separate chunk (DeDuplicates.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "DeDuplicates" */ "../views/ChildHomePages/DeDuplicates.vue"),
      },
      {
        path: "/DispositionCodes",
        name: "DispositionCodes",
        // route level code-splitting
        // this generates a separate chunk (DispositionCodes.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "DispositionCodes" */ "../views/ChildHomePages/DispositionCodes.vue"),
      },
      {
        path: "/AuditLogs",
        name: "AuditLogs",
        // route level code-splitting
        // this generates a separate chunk (DispositionCodes.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "DispositionCodes" */ "../views/ChildHomePages/AuditLogs.vue"),
      },
      {
        path: "/Settings",
        name: "Settings",
        // route level code-splitting
        // this generates a separate chunk (DispositionCodes.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings.vue"),
      },
      {
        path: "/CallAgentReports",
        name: "CallAgentReports",
        // route level code-splitting
        // this generates a separate chunk (CallAgentReports.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CallAgentReports" */ "../views/ChildHomePages/CallAgentReports.vue"),
      },
      // ProductMaster
      {
        path: "/ProductMaster",
        name: "ProductMaster",
        // route level code-splitting
        // this generates a separate chunk (ProductMaster.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "ProductMaster" */ "../views/ChildHomePages/ProductMaster.vue"),
      },
      {
        path: "/CallRecordings",
        name: "CallRecordings",
        // route level code-splitting
        // this generates a separate chunk (CallRecordings.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CallRecordings" */ "../views/ChildHomePages/CallRecordings.vue"),
      },
      {
        path: "/Reports",
        name: "Reports",
        // route level code-splitting
        // this generates a separate chunk (CallRecordings.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CallRecordings" */ "../views/ChildHomePages/Reports.vue"),
      },
      {
        path: "/CurateData",
        name: "CurateData",
        // route level code-splitting
        // this generates a separate chunk (DispositionCodes.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CurateData" */ "../views/ChildHomePages/CurateData.vue"),
      },
      {
        path: "/Dashboard-ATDC",
        name: "Dashboard-ATDC",
        component: () => import(/* webpackChunkName: "Help" */ "../views/ChildHomePages/DashboardATDC.vue"),
      },
    ],
  },
  {
    path: "/NoAccess",
    name: "NoAccess",
    component: NoAccess,
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import("@/views/ChildHomePages/ReportIssue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
