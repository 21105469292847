<template>
  <v-app>
    <Overlay :overlay="overlay" />
    <LogOutWarningDialog v-if="dialogLogoutWarning" :dialogLogoutWarning="dialogLogoutWarning" @clicked="dialogLogoutWarning = false" />
    <v-app-bar app clipped-left color="white" elevation="1">
      <v-app-bar-nav-icon color="background" v-if="$vuetify.breakpoint.name == 'xs'" @click="navDrawer = !navDrawer"></v-app-bar-nav-icon>
      <v-card class="elevation-0">
        <v-img src="@/assets/VOZCARE.png" width="150"></v-img>
      </v-card>
      <v-spacer></v-spacer>
      <span class="text-center">
        <div class="font-weight-bold FontSize" v-if="getCurrentUserDetailsObject.user_name">{{ getCurrentUserDetailsObject.user_name }}</div>
        <div class="fontSizeSmall">
          {{ $store.getters.get_user_email }}
        </div>
      </span>
      <v-btn icon to="/">
        <v-icon color="primary" large dark>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-0" align="center">
              <v-img max-width="200px" src="@/assets/accessDenied.png"></v-img>
              <div class="fontSize35px">No Access!!!</div>
              <div class="fontSize25px">
                You don't have access to any modules of this application. Please contact the administration team for further details.
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import RouterList from "@/JsonFiles/RouterList.json";

import Overlay from "@/components/Extras/Overlay.vue";
import LogOutWarningDialog from "@/components/Extras/LogOutWarningDialog.vue";

import { GetCurrentUser } from "@/mixins/Extras/GetCurrentUser.js";
export default {
  mixins: [GetCurrentUser],
  components: {
    Overlay,
    LogOutWarningDialog,
  },
  data: () => ({
    Height: 0,
    connectionDashboard: null,

    dialogLogoutWarning: false,
    navDrawer: true,
    overlay: false,
    RouterList: [],
    hasaccessRoutesList: [],
    selectedItem: 0,
  }),
  watch: {
    "$route.name"(value) {
      let currentRouteResult = this.hasaccessRoutesList.filter((route) => route.MenuRoute == value);
      if (currentRouteResult.length != 0) {
        this.$store.commit("SET_CURRENT_ROUTE_OBJECT", currentRouteResult[0]);
      }
    },
  },
  created() {
    this.RouterList = RouterList;
    this.GetCurrentUser();
    document.addEventListener("keyup", function (e) {
      var keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode == 44) {
        e.preventDefault();
      }
    });
    this.WebSocketConection();
  },
  beforeDestroy() {
    if (this.connectionDashboard) {
      this.connectionDashboard.close();
    }
  },
  methods: {
    checkRouteMethod(item) {
      if (this.$route.name != item.MenuRoute && item.has_child_routes == false) {
        for (let i = 0; i < this.hasaccessRoutesList.length; i++) {
          if (item.MenuRoute == this.hasaccessRoutesList[i].MenuRoute) {
            this.hasaccessRoutesList[i].active = true;
          } else {
            this.hasaccessRoutesList[i].active = false;
          }
          if (this.$route.name != item.MenuRoute) {
            this.$router.push(item.MenuRoute);
          }
        }
      }
      this.$forceUpdate();
    },
    WebSocketConection() {
      try {
        var self = this;
        self.connectionDashboard = new WebSocket(
          `wss://qnf0sr5uo9.execute-api.ap-south-1.amazonaws.com/dev?user_id=${self.$store.getters.get_current_user_details.user_id}&command=SIGN_IN_CUSTOMER`
        );
        self.connectionDashboard.onopen = function () {};
        self.connectionDashboard.onclose = function () {};
        self.connectionDashboard.onerror = function () {};
        self.connectionDashboard.onmessage = async function (event) {
          let resultObject = JSON.parse(event.data);
          switch (resultObject.command) {
            case "USER_LOGOUT":
              self.dialogLogoutWarning = true;
              self.$forceUpdate();
              break;
          }
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
