import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import { Amplify } from "aws-amplify";
import * as VueGoogleMaps from "vue2-google-maps";
import "@/plugins/apexcharts.js";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAF9RHWzeGsPjELTdrJSo83RqMx0Oc8Rkk",
    libraries: "places",
  },
});
Vue.config.productionTip = false;
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_GM2NoQRZj",
    region: "ap-south-1",
    userPoolWebClientId: "di6oeo3e8eb65d4erc3adfauv",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://mc6qdlfiffethh3csr45pdpilq.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "Report_issue",
        endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
    ],
  },
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
